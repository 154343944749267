import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { setCookie, hasCookie } from 'cookies-next';

const CookieBanner: React.FC<{}> = () => {
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const alreadyExist = hasCookie('showroom_cookies_accepted', { domain: 'showroom.shopping' });
    setIsOpen(!alreadyExist);
  }, []);

  const _acceptCookies = () => {
    setCookie('showroom_cookies_accepted', { domain: 'showroom.shopping' });
    setIsOpen(false);
  };

  return (
    <div
      className={`z-50 w-full bg-cream py-2 px-4 ${!isOpen && 'hidden'}`}
      style={{ boxShadow: '0px 0px 30px rgb(0 0 0 / 10%)' }}>
      <div className="flex items-center justify-between text-center align-middle sm:flex-row">
        <p className="text-center sm:text-left" style={{ fontSize: 14 }}>
          Vi använder <Link href="/privacy-policy">kakor</Link> för en bra upplevelse.
        </p>
        <button className={'btn-white outline-none sm:mt-0 sm:ml-4'} onClick={_acceptCookies}>
          OK
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
