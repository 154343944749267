import React from 'react';
import Head from 'next/head';
import type { Meta } from 'types/Meta.interface';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';

type Props = {
  title: string;
  meta: Meta[];
  isAuthenticated: boolean;
  children?: React.ReactNode;
  noFooter?: boolean;
};

const Layout: React.FC<Props> = ({ title, meta, isAuthenticated, children, noFooter = false }) => {
  if (!meta.length) {
    meta.push({
      name: 'description',
      content: 'Sveriges Mediehus för LiveShopping. Här möts tittare, e-handlare, varumärken och influencers Live. Vår affärsidé är att samla all LiveShopping på ett ställe.',
    })
  }
  return (
    <>
      <Head>
        <title>{title}</title>
        {meta?.map((meta) =>
          'name' in meta ? (
            <meta name={meta.name} content={meta.content} key={meta.name} />
          ) : (
            <meta property={meta.property} content={meta.content} key={meta.property} />
          )
        )}
      </Head>
      <div className="flex flex-col">
        <div className="flex min-h-screen flex-col order-2">
          <Navbar isAuthenticated={isAuthenticated} />
          <main>{children}</main>
          {noFooter ? null : <Footer />}
        </div>
        <div className="order-1 z-50">
          <CookieBanner />
        </div>
      </div>
    </>
  );
};

export default Layout;
